.category {
  box-sizing: border-box;
  .a-title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
  .category-wrap {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-top: 12px;
    padding: 20px;
  }
}
.ant-upload-picture-card-wrapper {
  width: 128px !important;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
