.app{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
 .login-form {
  max-width: 300px;
}
 .login-form-forgot {
  float: right;
}
.login-form-button {
  margin-top: 10px;
  width: 300px;
  position: relative;
}
.box{
  width: 600px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 2;
}
.title{
  width: 100%;
  display: flex;
  justify-content: center;
}
.loginText{
  display: inline-block;
  font-size: 24px;
  vertical-align: middle
}

.drag{
  width: 300px;
  height: 40px;
  line-height: 40px;
  background-color: #f1f1f1;
  position: relative;
  margin:0 auto;
}
.bg{
  width:40px;
  height: 100%;
  position: absolute;
  background-color: #75CDF9;
}
.text{
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  user-select: none;
  color: #51555c;
  font-size: 12px;
}
.btn{
  width:50px;
  height: 40px;
  position: absolute;
  /* border:1px solid #ccc; */
  cursor: move;
  font-family: "宋体";
  text-align: center;
  /* background-color: #fff; */
  user-select: none;
  color:#666;
  background:#FE4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAiCAYAAAApkEs2AAAA/UlEQVRYhe3XLc9GYBjG8eN6dkdFIQuqoCiCbj6ob2CCIplRaJKqEHT3c/kAz66Xc/fLnvO3UY7N/jNmxDAMF77AQ57iOH53x5/GccTPuyNUcSg1DqXGodQ4lNr/CC3LEsuyaG8mrEKzLEPXdZjnWWszYRXq+z7yPMc0Tej7XnkzYf2Muq6LoiiwrivatsV1XUrby0Mlx3Huu7fvO5qmUd50kL31QgijTdXD+gq/zvNEVVXwPA9pmipvOqxDj+O4Q4IgQJIkypsuq9Bt21DXNaIoug/VzYRVqHw55J0Kw1BrMyHkXyj/3BHiUGocSo1DqXEota8Jvb/18hP16Z7qL3h/w53n4AAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 100% 100%;
}
.errr{
  color: #f5222d;
  /* position: absolute; */
  /* top:105px; */
} 
#particles{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}