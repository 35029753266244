body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,body{
  height: 100%;
}
#root {
  height: 100%;
  font-size: 16px;
  background-color: #F1F1F1;
  color: #363636;
}
.ant-card-bordered{
  border:none !important;
}
.ant-calendar-input{
  line-height: normal !important;
}